
        export default [{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/about/ContentNews.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => '查看详情',
        width: '800px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/about/EditModal.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => props.record?.code !== 'tianjia' ? '修改' : '添加',
        width: '800px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/about/index.jsx',
            component: ()=> import('E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/about/index.jsx'),
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    path: '/about',
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/aboutDetalis/ContentNews.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => '查看详情',
        width: '800px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/aboutDetalis/EditModal.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => props.record?.code !== 'tianjia' ? '修改' : '添加',
        width: '800px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/aboutDetalis/index.jsx',
            component: ()=> import('E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/aboutDetalis/index.jsx'),
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    path: '/aboutDetalis',
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/afterSales/ContentNews.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => '查看信息',
        width: '800px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/afterSales/EditModal.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => props?.record?.code === 'bianji' ? '编辑信息' : '添加信息',
        width: '800px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/afterSales/index.jsx',
            component: ()=> import('E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/afterSales/index.jsx'),
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    path: '/afterSales',
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/banner/AddCountry.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => props?.record?.code === 'bianji' ? '编辑banner' : '添加banner',
        width: '450px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/banner/ContentNews.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => '详情',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/banner/EditModal.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => props?.record?.code === 'bianji' ? '编辑banner' : '添加banner',
        width: '450px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/banner/index.jsx',
            component: ()=> import('E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/banner/index.jsx'),
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    path: '/banner',
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/categoryCountry/AddCountry.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => props.isEdit ? '修改国家' : '添加',
        width: '450px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/categoryCountry/ContentNews.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => '详情',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/categoryCountry/EditModal.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => 'News',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/categoryCountry/index.jsx',
            component: ()=> import('E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/categoryCountry/index.jsx'),
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    path: '/categoryCountry',
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/chatKeyword/AddCountry.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => '新增关键词',
        width: '450px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/chatKeyword/ContentNews.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => '详情',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/chatKeyword/EditModal.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => '新增关键词',
        width: '450px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/chatKeyword/index.jsx',
            component: ()=> import('E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/chatKeyword/index.jsx'),
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    path: '/chatKeyword',
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/crossBorderSupply/ContentNews.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => '查看详情',
        width: '800px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/crossBorderSupply/EditModal.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => props?.record?.code === 'bianji' ? '编辑详情' : '新增详情',
        width: '800px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/crossBorderSupply/index.jsx',
            component: ()=> import('E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/crossBorderSupply/index.jsx'),
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    path: '/crossBorderSupply',
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/customerService/ContentNews.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: () => '编辑新闻',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/customerService/EditModal.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => '详情',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/customerService/index.jsx',
            component: ()=> import('E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/customerService/index.jsx'),
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    path: '/customerService',
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/customerService/RoleSelectTable.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        },{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/home/index.jsx',
            component: ()=> import('E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/home/index.jsx'),
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    path: '/',
    title: '首页',
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/homeAnimation/ContentNews.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => '查看模块信息',
        width: '800px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/homeAnimation/EditModal.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => props.record?.code !== 'tianjia' ? '修改模块信息' : '添加模块信息',
        width: '800px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/homeAnimation/index.jsx',
            component: ()=> import('E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/homeAnimation/index.jsx'),
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    path: '/homeAnimation',
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/homeLink/AddCountry.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => props?.record?.code === 'bianji' ? '编辑面板链接' : '新增面板链接',
        width: '450px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/homeLink/ContentNews.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => '查看面板链接',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/homeLink/EditModal.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => props?.record?.code === 'bianji' ? '编辑面板链接' : '新增面板链接',
        width: '450px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/homeLink/index.jsx',
            component: ()=> import('E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/homeLink/index.jsx'),
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    path: '/content',
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/hotLink/AddCountry.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => props?.record?.code === 'bianji' ? '编辑面板链接' : '新增面板链接',
        width: '450px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/hotLink/ContentNews.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => '查看面板链接',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/hotLink/EditModal.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => props?.record?.code === 'bianji' ? '编辑面板链接' : '新增面板链接',
        width: '450px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/hotLink/index.jsx',
            component: ()=> import('E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/hotLink/index.jsx'),
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    path: '/hotContent',
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/immigrantCountries/ContentNews.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => '查看详情',
        width: '800px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/immigrantCountries/EditModal.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => props?.record?.code === 'bianji' ? '编辑详情' : '新增详情',
        width: '800px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/immigrantCountries/index.jsx',
            component: ()=> import('E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/immigrantCountries/index.jsx'),
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    path: '/immigrantCountries',
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/immigrationCategory/ContentNews.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => '查看详情',
        width: '800px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/immigrationCategory/EditModal.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => props?.record?.code === 'bianji' ? '编辑详情' : '新增详情',
        width: '800px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/immigrationCategory/index.jsx',
            component: ()=> import('E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/immigrationCategory/index.jsx'),
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    path: '/immigrationCategory',
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/immigrationContinent/AddCountry.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => props.isEdit ? '修改洲' : '添加洲',
        width: '450px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/immigrationContinent/ContentNews.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => '详情',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/immigrationContinent/EditModal.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => 'News',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/immigrationContinent/index.jsx',
            component: ()=> import('E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/immigrationContinent/index.jsx'),
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    path: '/immigrationContinent',
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/immigrationCountry/AddCountry.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => props.isEdit ? '修改国家' : '添加国家',
        width: '450px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/immigrationCountry/ContentNews.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => '详情',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/immigrationCountry/EditModal.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => 'News',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/immigrationCountry/index.jsx',
            component: ()=> import('E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/immigrationCountry/index.jsx'),
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    path: '/immigrationCountry',
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/immigrationIntentions/ContentNews.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: () => '编辑新闻',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/immigrationIntentions/EditModal.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => '详情',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/immigrationIntentions/index.jsx',
            component: ()=> import('E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/immigrationIntentions/index.jsx'),
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    path: '/immigrationIntentions',
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/immigrationIntentions/RoleSelectTable.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        },{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/immigrationStrategy/ContentNews.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => '查看攻略',
        width: '800px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/immigrationStrategy/EditModal.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => props?.record?.code === 'bianji' ? '编辑攻略' : '新增攻略',
        width: '800px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/immigrationStrategy/index.jsx',
            component: ()=> import('E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/immigrationStrategy/index.jsx'),
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    path: '/immigrationStrategy',
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/journalism/ContentNews.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => '详情',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/journalism/EditModal.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => 'News',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/journalism/index.jsx',
            component: ()=> import('E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/journalism/index.jsx'),
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    path: '/news',
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/journalism/RoleSelectTable.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        },{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/login/index-full.jsx',
            component: ()=> import('E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/login/index-full.jsx'),
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    path: '/f/login',
    auth: false,
    layout: false,
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/login/index-portal.jsx',
            component: ()=> import('E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/login/index-portal.jsx'),
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    path: '/p/login',
    auth: false,
    layout: false,
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/login/index.jsx',
            component: ()=> import('E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/login/index.jsx'),
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    path: '/login',
    auth: false,
    layout: false,
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/menus/ActionEdit.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        },{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/menus/index.jsx',
            component: ()=> import('E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/menus/index.jsx'),
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    path: '/menus',
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/menus/MenuEdit.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        },{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/menus/MenuTableSelect.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        },{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/menus/SystemSelect.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        },{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/page-configs.js',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        },{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/prefecture/ContentNews.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => '查看详情',
        width: '800px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/prefecture/EditModal.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => props.record?.code !== 'tianjia' ? '修改详情' : '添加详情',
        width: '800px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/prefecture/index.jsx',
            component: ()=> import('E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/prefecture/index.jsx'),
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    path: '/prefecture',
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/recruit/ContentNews.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => '编辑',
        width: '800px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/recruit/EditModal.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => props?.record?.code === 'bianji' ? '编辑招聘' : '新增招聘',
        width: '800px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/recruit/index.jsx',
            component: ()=> import('E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/recruit/index.jsx'),
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    path: '/recruit',
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/role/EditModal.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => (props.isEdit ? '编辑角色' : '创建角色'),
        width: '70%',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/role/index.jsx',
            component: ()=> import('E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/role/index.jsx'),
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    path: '/sys/role',
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/role/RoleSelectTable copy.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        },{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/role/RoleSelectTable.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        },{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/systemConfig/ContentNews.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => '详情',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/systemConfig/EditModal.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => '首页配置添加',
        width: '450px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/systemConfig/index.jsx',
            component: ()=> import('E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/systemConfig/index.jsx'),
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    path: '/systemConfig',
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/talentPhilosophy/ContentNews.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => '查看理念',
        width: '800px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/talentPhilosophy/EditModal.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => props?.record?.code === 'bianji' ? '编辑理念' : '新增理念',
        width: '800px',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/talentPhilosophy/index.jsx',
            component: ()=> import('E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/talentPhilosophy/index.jsx'),
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    path: '/talentPhilosophy',
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/user/EditModal.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return '显示用户';

            return props.isEdit ? '编辑用户' : '创建用户';
        },
        width: "600px",
        top: 150,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/user/index.jsx',
            component: ()=> import('E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/user/index.jsx'),
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    path: '/sys/user',
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/user/ResetPassword.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => {

            return `重置管理员密码`;
        },
        top: 200,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/userComment/ContentNews.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: () => '编辑新闻',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/userComment/EditModal.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    modal: {
        title: (props) => '详情',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/userComment/index.jsx',
            component: ()=> import('E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/userComment/index.jsx'),
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        
    path: '/userComment',
},{
            filePath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages/userComment/RoleSelectTable.jsx',
            
            pagesPath: 'E:/desktop/新建文件夹 (25)/cccAdmin/src/pages',
        }]
    