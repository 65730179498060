import {Form, Input, message} from 'antd';
import {ModalContent, FormItem} from '@ra-lib/admin';
import {toLogin} from 'src/commons';
import config from 'src/commons/config-hoc';

export default config({
    modal: {
        title: '更改密码',
        width: 500,
    },
})(function PasswordModal(props) {
    const {onOk, onCancel, ajax} = props;

    async function handleSubmit(values) {
        // alert('TODO 接口对接');
        await ajax
            .post('/api/v1/sys/user/updatePwd', {newPwd: values?.password, oldPwd: values?.oldPassword})
            .then((res) => {
                if (res?.code === 0) {
                    message.success('修改成功请重新登录！');
                    setTimeout(() => {
                        toLogin()
                    }, 1000);
                } else {
                    message.error(res?.msg);
                }
                onOk();
            })
            .catch(() => []);
    }

    // const layout = {
    //     labelCol: { flex: '150px' },
    // };
    const layout = {
        labelCol: {
            xs: {span: 12},
            sm: {span: 10},
        },
        // wrapperCol: {
        //   xs: { span: 12 },
        //   sm: { span: 24 },
        // },
    };

    return (
        <Form onFinish={handleSubmit}>
            <ModalContent okHtmlType="submit" onCancel={onCancel} okText="确认" cancelText="取消">
                <FormItem
                    labelAlign="left"
                    {...layout}
                    type="password"
                    label="旧密码"
                    name="oldPassword"
                    rules={[{required: true, message: '请输入旧密码!'}]}
                >
                    <Input />
                </FormItem>
                <FormItem
                    labelAlign="left"
                    {...layout}
                    type="password"
                    label="新密码"
                    name="password"
                    // rules={[{required: true, message: '输入新密码!'}]}
                    
                    rules={[
                        () => ({ 
                            validator(_,pwd) {
                                  let reg =/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/
                                  let re = new RegExp(reg)
                                  if (re.test(pwd)) { 
                                    return Promise.resolve()
                                  } else {
                                    return Promise.reject("输入8-16位数字、字母的组合");
                                  }
                            }
                         })
                    ]}
                >
                    <Input />
                </FormItem>
                <FormItem
                    {...layout}
                    labelAlign="left"
                    type="password"
                    label="确认新密码"
                    name="rePassword"
                    dependencies={['password']}
                    rules={[
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value) {
                                    return Promise.reject(new Error('输入确认新密码！'));
                                }
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    new Error(
                                        '两次输入的密码必须一致',
                                    ),
                                );
                            },
                        }),
                    ]}
                >
                    <Input />
                </FormItem>
            </ModalContent>
        </Form>
    );
});
