// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root_2INz0 {\n  display: flex;\n  align-items: center;\n}\n.root_2INz0 img {\n  width: 50px;\n  margin-right: 16px;\n}\n.root_2INz0 h1 {\n  color: #fff;\n  margin: 0;\n}\n", "",{"version":3,"sources":["webpack://src/components/logo/style.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;AACJ;AAHA;EAKQ,WAAA;EACA,kBAAA;AACR;AAPA;EAUQ,WAAA;EACA,SAAA;AAAR","sourcesContent":[".root {\n    display: flex;\n    align-items: center;\n\n    img {\n        width: 50px;\n        margin-right: 16px;\n    }\n\n    h1 {\n        color: #fff;\n        margin: 0;\n    }\n}\n\n@packageName: react-admin;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root_2INz0"
};
export default ___CSS_LOADER_EXPORT___;
