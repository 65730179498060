export default {
  /**
   * 初始化 state
   *
   * 推荐将model中用到的所有state，都进行初始化：
   * 1. 合理的初始化值，可以避免使用错误；
   * 2. 可以通过state列表快速知晓当前model提供的数据结构
   * */
  state: {
    state: [],
  },
};
